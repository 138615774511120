<template>
    <div class="left_button_box">
        <div class="btns2">
            <router-link tag="button" class="btn-2 bg-green" style="border-radius: 10px 0 0 0;color: #fff9ff" :to="{path: '/recharge', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/topbar/입금02.png"/> 충전
            </router-link>
            <router-link tag="button" class="btn-2 lr bg-green" style="color: #fff9ff" :to="{path: '/exchange', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/topbar/출금02.png"/> 환전
            </router-link>
            <router-link tag="button" class="btn-2 bg-green" style="border-radius: 0 10px 0 0;color: #fff9ff" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/topbar/문의02.png"/> 문의
            </router-link>
        </div>
        <div class="btns2" style="">
            <router-link tag="button" class="btn-2" :to="{path: '/sports', query: {t: new Date().getTime()}}">스포츠
            </router-link>
            <router-link tag="button" class="btn-2 lr" :to="{path: '/sports_special', query: {t: new Date().getTime()}}">
               스페셜
            </router-link>
            <router-link tag="button" class="btn-2 " :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}">
                LIVE스포츠
            </router-link>

        </div>
        <div class="btns2" style="">
            <router-link tag="button" class="btn-2"
                         :to="{path: '/leisure/eospowerball5m', query: {t: new Date().getTime()}}">미니게임
            </router-link>
            <router-link tag="button" class="btn-2 lr"
                         :to="{path: '/leisure/bet365_superleague', query: {t: new Date().getTime()}}">가상스포츠
            </router-link>

            <router-link tag="button" class="btn-2" :to="{path: '/sports_result', query: {t: new Date().getTime()}}">경기결과
            </router-link>

<!--            <router-link tag="button" class="btn-2"-->
<!--                         :to="{path: '/coupon', query: {type:2,t: new Date().getTime()}}">쿠폰-->
<!--            </router-link>-->
        </div>
        <div class="btns2" style="">
<!--            <router-link tag="button" class="btn-2" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">출석부-->
<!--            </router-link>-->

            <router-link tag="button" class="btn-2 " :to="{path: '/notice', query: {t: new Date().getTime()}}">공지사항
            </router-link>
            <router-link tag="button" class="btn-2 lr" :to="{path: '/event', query: {t: new Date().getTime()}}">이벤트
            </router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/message', query: {t: new Date().getTime()}}">
                쪽지
            </router-link>

           <!-- <router-link tag="button" class="btn-2 " to="" @click.native="weekpayback">페이백
            </router-link>-->
        </div>

        <div style="display: flex;justify-content: space-between;align-items: center;margin: 5px 0">
            <router-link  :to="{path: '/casino', query: {type: 1}}" tag="div" style="width: 49.5%;cursor: pointer">
                <img src="../assets/images/left/icon/casino.jpg" style="width: 100%" alt="">
            </router-link>
            <router-link   :to="{path: '/casino', query: {type: 2}}" tag="div" style="width: 49.5%;cursor: pointer">
                <img src="../assets/images/left/icon/slot.jpg" style="width: 100%" alt="">
            </router-link>
        </div>
    </div>
</template>

<script>
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {changePoint, logout, weekpayback} from "../network/userRequest";

    export default {
        name: "LeftButtonsComp",
        methods: {
            initUserInfo() {
                this.$store.dispatch('actionUserInfo')
            },
            weekpayback() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                weekpayback().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '주간 페이백 지급 성공',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .left_button_box {
        width: 100%;
        padding: 0;
    }

    .left_button_box .btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 0px 0;
        border-bottom: 1px solid #d9cdb5;
    }

    .left_button_box .btns .btn-1 {
        width: 33.33%;
        height: 34px;
        background-color: var(--leftBarBtnBg);
        color: #e7e7e7;
        margin: 1px 0;
        border-radius: 0px;
        font-weight: bold;
    }

    .left_button_box .btns .btn-1 img {
        width: 20px;
        vertical-align: middle;
    }
    .left_button_box .btns2 .btn-2 img {
        width: 20px;
        vertical-align: middle;
    }

    .left_button_box .btns2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        --background-color: #eaeaea;
        font-weight: bold;
    }

    .left_button_box .btns2 .btn-2 {
        width: 33.33%;
        height: 40px;
        border-bottom: 1px solid #222b32;

        font-weight: bold;
        font-size: 12px;
    }

    .left_button_box .btns2 .lr {
        border-left: 1px solid #222b32;
        border-right: 1px solid #222b32;
    }
    .bg-green{
        background: linear-gradient(180deg, #00bcf9, #025fea);
    }

    @media screen and (max-width: 1024px) {
        .left_button_box {
            display: none !important;
        }
    }
</style>